import { Button, Collapse, Typography, Carousel } from 'antd';
import Panel from 'antd/es/cascader/Panel';
import styled, { keyframes } from 'styled-components';

const { Title } = Typography;

export const StyledCarousel = styled(Carousel)`
  .slick-slide img {
    width: 100%;
    height: auto;
    min-height: 350px;
    max-height: 400px;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const CustomCollapse = styled(Collapse)`
  .ant-collapse-content,
  .ant-collapse-header {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 768px) {
    .ant-collapse-content,
    .ant-collapse-header {
      max-width: 100%;
    }
  }
`;

export const CustomPanel = styled(Panel)`
  && {
    background-color: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 5px;
    border: 0;
    overflow: hidden;
    margin-bottom: 24;
  }
`;

export const LargeScreenTitle = styled(Title)`
  text-align: center;
  margin-bottom: 50px !important;
  font-size: 2rem;
  font-family: 'Poppins';

  @media (max-width: 768px) {
    font-size: 1rem !important;
  }
`;

const shadowPulse = keyframes`
  0% {
    box-shadow: 0 0 0 0px rgba(120, 82, 125, 0.4);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(120, 82, 125, 0);
  }
`;

export const AnimatedButton = styled(Button)`
  font-family: 'Poppins';
  animation: ${shadowPulse} 2s infinite;
`;
