import { Form, Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  top: 0;
  @media (max-width: 768px) {
    .ant-modal {
      width: 100% !important;
      margin: 0;
    }
  }
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    label {
      color: #8c8c8c;
    }
  }
  .ant-form-item-control-input-content {
    input,
    .ant-input-affix-wrapper {
      border-radius: 5px;
    }
  }
`;

export const Icon = styled.span`
  color: rgba(0, 0, 0, 0.25);
  margin-right: 8px;
`;
