import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-form-item-label label,
  .ant-input,
  .ant-typography,
  .ant-table,
  .ant-button,
  .ant-select,
  .ant-card,
  .ant-message,
  .ant-message-notice-content,
  .ant-list,
  .ant-list-item,
  .ant-btn {
    font-family: 'Poppins', sans-serif !important;
  }
`;
