import { Breadcrumb, Layout } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const { Content } = Layout;

const ContentBreadcrumb = () => {
  const location = useLocation();

  let breadcrumbName;
  switch (location.pathname) {
    case '/schedule':
      breadcrumbName = 'Calendário';
      break;
    case '/login':
      breadcrumbName = 'Área do usuário';
      break;
    case '/admin':
      breadcrumbName = 'Painel administrativo';
      break;
    case '/about':
      breadcrumbName = 'Saiba Mais';
      break;
    case '/media':
      breadcrumbName = 'Mídias';
      break;
    default:
      breadcrumbName = 'Agenda';
  }

  return (
    <Content className="site-layout-background" style={{ padding: 15, marginBottom: -20 }}>
      <Breadcrumb style={{ fontFamily: 'Poppins' }}>
        <Breadcrumb.Item>
          <Link to="/">Espaço Maljj</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <strong style={{ color: '#3351A7', opacity: '70%' }}>{breadcrumbName}</strong>
        </Breadcrumb.Item>
      </Breadcrumb>
    </Content>
  );
};

export default ContentBreadcrumb;
