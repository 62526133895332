import { Button, Modal, Select } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-header {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background-color: #f0f2f5;
    }
    .ant-modal-title {
      color: #5c6ac4;
    }
    .ant-modal-body {
      padding: 24px;
    }
    .ant-modal-footer {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 5px !important;
    height: 40px !important;
    align-items: center;
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)`
  border-radius: 5px;

  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
