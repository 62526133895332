export const fetchReservation = () => ({
  type: 'FETCH_RESERVATION',
});

export const fetchReservedDates = () => ({
  type: 'FETCH_RESERVED_DATES',
});

export const approveReservation = (requestId: any) => ({
  type: 'APPROVE_RESERVATION',
  payload: requestId,
});

export const reproveReservation = (requestId: any) => ({
  type: 'REPROVE_RESERVATION',
  payload: requestId,
});

export const cancelReservation = (requestId: any) => ({
  type: 'CANCEL_RESERVATION',
  payload: requestId,
});

export const registerReservation = (payload: any) => ({
  type: 'REGISTER_RESERVATION',
  payload,
});

export const updateReservation = (payload: any) => ({
  type: 'UPDATE_RESERVATION',
  payload,
});
