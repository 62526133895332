import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReservation, fetchReservedDates } from '../../store/reservation/actions';
import { message } from 'antd';
import { format } from 'date-fns';
import ModalReservation from '../Modals/ModalReservation';
import ModalPayment from '../Modals/ModalPayment';
import { StyledCalendar, StyledRow } from './styled';
import { RootState } from '../../store/types';

function CustomCalendar() {
  const dispatch = useDispatch();
  const reservedDates = useSelector((state: RootState) => state.reservation.reservedDates);

  useEffect(() => {
    dispatch(fetchReservedDates());
  }, [dispatch]);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (date: Date) => {
    setSelectedDate(date);
    setIsModalOpen(true);
  };

  const handleDateClick = (date: Date) => {
    const isoDate = date.toISOString().split('T')[0];
    const formattedDate = format(date, 'dd/MM/yy');
    const reservedDate = reservedDates.find((reservedDate: any) => reservedDate.date === isoDate);
    if (!reservedDate || !reservedDate.approved) {
      openModal(date);
    } else {
      message.warning(`O dia ${formattedDate} já está reservado!`);
    }
  };

  const saveReserve = async () => {
    setIsPaymentModalOpen(true);
    dispatch(fetchReservedDates());
    dispatch(fetchReservation());
  };

  const handlePaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  const handlePaymentOptionSelect = (option: string) => {
    setSelectedPaymentOption(option);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const today = new Date();
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (view === 'month' && date < today) {
      return 'disabled';
    }
    const isoDate = date.toISOString().split('T')[0];
    const reservedDate = reservedDates.find((reserve: any) => reserve.date === isoDate);
    if (reservedDate && reservedDate.approved) {
      return 'calendar-day--unavailable';
    } else {
      return 'calendar-day';
    }
  };

  return (
    <StyledRow>
      <StyledCalendar onClickDay={handleDateClick} className="calendar" tileClassName={tileClassName} minDate={today} />
      <ModalReservation isOpen={isModalOpen} handleClose={closeModal} sendReserve={saveReserve} selectedDate={selectedDate} />
      <ModalPayment
        isOpen={isPaymentModalOpen}
        handleClose={handlePaymentModalClose}
        selectedDate={selectedDate}
        handlePaymentOptionSelect={handlePaymentOptionSelect}
      />
    </StyledRow>
  );
}

export default CustomCalendar;
