import React, { useState } from 'react';
import { Button, Input, Modal, message } from 'antd';
import { StyledMaskedInput } from './styled';
import { useDispatch } from 'react-redux';
import { registerReservation } from '../../../store/reservation/actions';
import { format } from 'date-fns';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  sendReserve: () => void;
  selectedDate: Date | null;
}

const ModalReservation: React.FC<ModalProps> = ({ isOpen, handleClose, sendReserve, selectedDate }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [phone, setPhone] = useState('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDocumentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDocument(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const onClose = () => {
    setName('');
    setDocument('');
    setPhone('');
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      if (name && document && phone) {
        const formattedDate = selectedDate ? selectedDate.toISOString().split('T')[0] : null;
        const reservationData = {
          selectedDate: formattedDate,
          approvalDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
          name,
          document,
          phone,
          approved: false,
          amount: 0.0,
          entry: 0.0,
          payOff: false,
          remaining: 0.0,
          received: 0.0,
        };
        dispatch(registerReservation(reservationData));
        sendReserve();
        onClose();
        message.success('Sua pré-reserva foi realizada com sucesso!');
      } else {
        message.warning('Preencha todos os campos para continuar!');
      }
    } catch (error) {
      console.error('Erro ao enviar o agendamento:', error);
    }
  };

  return (
    <Modal
      title="Solicitação de reserva"
      visible={isOpen}
      onOk={handleSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Confirmar
        </Button>,
      ]}
    >
      <>
        <Input
          value={selectedDate?.toLocaleDateString('pt-BR')}
          disabled
          style={{ marginTop: 25, marginBottom: '0.5rem' }}
        />
        <Input
          placeholder="Nome completo"
          value={name}
          onChange={handleNameChange}
          style={{ marginBottom: '0.5rem' }}
        />
        <Input
          placeholder="Documento"
          type="document"
          value={document}
          onChange={handleDocumentChange}
          style={{ marginBottom: '0.5rem' }}
        />
        <StyledMaskedInput
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          onChange={handlePhoneChange}
          value={phone}
          placeholder="Telefone"
        />
      </>
    </Modal>
  );
};

export default ModalReservation;
