import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import ModalDetails from '../../components/Modals/ModalDetails';
import CustomCalendar from '../../components/CustomCalendar';
import { Alert, Card, Statistic, Table, message } from 'antd';
import {
  CheckCircleOutlined,
  ScheduleOutlined,
  RocketOutlined,
  MoneyCollectOutlined,
  CaretRightOutlined,
  FundProjectionScreenOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import ModalCancelReservation from '../../components/Modals/ModalCancelReservation';
import ModalCancelRequest from '../../components/Modals/ModalCancelRequest';
import { columnsFutureReservation, columnsPreReservation, columnsReservation } from './interfaces';
import { RootState } from '../../store/types';
import { fetchReservation, approveReservation, fetchReservedDates } from '../../store/reservation/actions';
import { RangeDatePicker } from './styled';
import { Reservation } from '../../store/reservation/types';
import { startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { calculateTotalAmount, calculateTotalReceived, calculateTotalRemaining } from '../../services/treatmentValues';

function AdminScreen() {
  const dispatch = useDispatch();
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const reservation = useSelector((state: RootState) => state.reservation.reservation);
  const [reservationAllApproved, setReservationAllApproved] = useState<Reservation[]>([]);
  const [reservationFiltered, setReservationFiltered] = useState<Reservation[]>([]);
  const [reservationPending, setReservationPending] = useState<Reservation[]>([]);
  const [reservationFutureApproved, setReservationFutureApproved] = useState<Reservation[]>([]);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [isModalCancelReservationOpen, setIsModalCancelReservationOpen] = useState(false);
  const [isModalCancelRequest, setIsModalCancelRequest] = useState(false);
  const [reservationSelected, setReservationSelected] = useState<Reservation>({} as Reservation);
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    setReservationAllApproved(reservation.filter((reserve) => reserve.approved === true));
    setReservationFiltered([]);
    setReservationPending(reservation.filter((reserve) => reserve.approved === false));
    setReservationFutureApproved(
      reservation.filter((event) => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const eventDate = new Date(event.selectedDate);
        eventDate.setHours(0, 0, 0, 0);
        return eventDate.getTime() >= currentDate.getTime() && event.approved;
      }),
    );
  }, [reservation]);

  useEffect(() => {
    dispatch(fetchReservation());
    dispatch(fetchReservedDates());
  }, []);

  const handleDetails = async (reservationId: string) => {
    const reserve = reservation.filter((r) => r.id === reservationId);
    setReservationSelected(reserve[0]);
    openModalDetails();
  };

  const handleCancelReservation = async (reservationId: string) => {
    const reserve = reservation.filter((r) => r.id === reservationId);
    setReservationSelected(reserve[0]);
    setIsModalCancelReservationOpen(true);
  };

  const handleCancelRequest = async (reservationId: string) => {
    const reserve = reservation.filter((r) => r.id === reservationId);
    setReservationSelected(reserve[0]);
    setIsModalCancelRequest(true);
  };

  const handleDateRangeChange = (dates: any) => {
    if (dates !== null) {
      setDateRange(dates);
      const [startDate, endDate] = dates;
      if (startDate && endDate) {
        const filteredData = reservationAllApproved.filter((item: any) => {
          const startOfDayStart = startOfDay(new Date(startDate));
          const startOfDayEnd = startOfDay(new Date(endDate));
          const reservationDate = new Date(item.selectedDate);
          const reservationDateGMT = utcToZonedTime(reservationDate, 'GMT');
          return reservationDateGMT >= startOfDayStart && reservationDateGMT <= startOfDayEnd;
        });
        setReservationFiltered(filteredData);
      } else {
        setReservationFiltered(reservationAllApproved);
      }
    } else {
      setReservationFiltered([]);
      clearDateRange();
    }
  };

  const clearDateRange = () => {
    setDateRange([null, null]);
  };

  const handleApproveReservation = async (requestId: any) => {
    dispatch(approveReservation(requestId));
    message.success('A reserva foi aprovada com sucesso!');
  };

  const openModalDetails = () => {
    setIsModalDetailsOpen(true);
  };

  const closeModalDetails = () => {
    setReservationSelected({} as Reservation);
    setIsModalDetailsOpen(false);
    clearDateRange();
  };

  const closeModalCancelReservation = () => {
    setIsModalCancelReservationOpen(false);
  };

  const closeModalCancelRequest = () => {
    setIsModalCancelRequest(false);
  };

  return (
    <motion.div initial="hidden" animate="visible" variants={variants} transition={{ duration: 1.0 }}>
      <div style={{ marginBottom: 10 }}>
        <Card
          title={
            <span>
              <ScheduleOutlined /> Calendário de reservas
            </span>
          }
        >
          <CustomCalendar />
        </Card>
      </div>
      <div style={{ marginBottom: 10 }}>
        {reservationPending.length > 0 ? (
          <>
            <Card
              title={
                <span>
                  <CheckCircleOutlined /> Solicitações de reserva
                </span>
              }
              extra={<Statistic value={reservationPending.length} />}
            >
              <Table
                size="small"
                columns={columnsPreReservation(handleApproveReservation, handleCancelRequest)}
                dataSource={reservationPending}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </>
        ) : (
          <>
            <Alert message="Aviso" description="Nenhum pedido de reserva encontrado." type="warning" />
          </>
        )}
      </div>
      <div style={{ marginBottom: 10 }}>
        {reservationFutureApproved.length > 0 ? (
          <>
            <Card
              title={
                <span>
                  <CaretRightOutlined /> Datas confirmadas
                </span>
              }
              extra={<Statistic value={reservationFutureApproved.length} />}
            >
              <Table
                size="small"
                columns={columnsFutureReservation(handleDetails, handleCancelReservation)}
                dataSource={reservationFutureApproved}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </>
        ) : (
          <>
            <Alert message="Aviso" description="Nenhuma reserva futura encontrada." type="warning" />
          </>
        )}
      </div>
      <div style={{ marginBottom: 10 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <RangeDatePicker
            value={dateRange}
            onChange={handleDateRangeChange}
            placeholder={['Início', 'Fim']}
            format="DD/MM/YYYY"
            picker="date"
          />
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 10,
              marginBottom: 10,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Card
              title={
                <span>
                  <RocketOutlined /> Total Período
                </span>
              }
              style={{
                flex: '1 1 calc(50% - 10px)',
                margin: '5px',
                minWidth: '300px',
              }}
            >
              <strong>{calculateTotalAmount(reservationFiltered)}</strong>
            </Card>
            <Card
              title={
                <span>
                  <CloudUploadOutlined /> Total Recebido
                </span>
              }
              style={{
                flex: '1 1 calc(50% - 10px)',
                margin: '5px',
                minWidth: '300px',
              }}
            >
              <strong>{calculateTotalReceived(reservationFiltered)}</strong>
            </Card>
            <Card
              title={
                <span>
                  <MoneyCollectOutlined /> Total Pendente
                </span>
              }
              style={{
                flex: '1 1 calc(50% - 10px)',
                margin: '5px',
                minWidth: '300px',
              }}
            >
              <strong>{calculateTotalRemaining(reservationFiltered)}</strong>
            </Card>
          </div>
        </div>
        {reservationFiltered.length > 0 ? (
          <>
            <Card
              title={
                <span>
                  <FundProjectionScreenOutlined /> Gestão e controle
                </span>
              }
              extra={<Statistic value={reservationFiltered.length} />}
            >
              <Table
                size="small"
                columns={columnsReservation(handleDetails, handleCancelReservation)}
                dataSource={reservationFiltered}
                pagination={{ pageSize: 5 }}
                scroll={{ x: 'max-content' }}
              />
            </Card>
          </>
        ) : (
          <>
            <Alert message="Aviso" description="Nenhuma registro encontrado." type="warning" />
          </>
        )}
      </div>
      <ModalCancelRequest requestReservation={reservationSelected} isOpen={isModalCancelRequest} handleClose={closeModalCancelRequest} />
      <ModalCancelReservation
        reservation={reservationSelected}
        isOpen={isModalCancelReservationOpen}
        handleClose={closeModalCancelReservation}
      />
      <ModalDetails isOpen={isModalDetailsOpen} handleClose={closeModalDetails} reservation={reservationSelected} />
    </motion.div>
  );
}

export default AdminScreen;
