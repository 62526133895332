import { Card, Button, Layout } from 'antd';
import FuturisticCarousel from '../../components/FuturisticCarousel';
import { motion } from 'framer-motion';

function MediaScreen() {
  return (
    <Layout>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <FuturisticCarousel />
        <Card style={{ marginTop: 10 }}>
          <h2>Acompanhe também nas redes sociais!</h2>
          <p>Explore nosso perfil no Instagram para ver mais detalhes e imagens incríveis!</p>
          <Button type="primary" href="https://www.instagram.com/espaco_maljj/" target="_blank" rel="noopener noreferrer">
            Visitar Instagram
          </Button>
        </Card>
      </motion.div>
    </Layout>
  );
}

export default MediaScreen;
