import { Reducer } from 'redux';
import { ReservationState, ReservationActionTypes } from './types';

const initialState: ReservationState = {
  reservation: [],
  reservedDates: [],
};

export const reducer: Reducer<ReservationState, ReservationActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RESERVATION':
      return {
        ...state,
        reservation: action.payload,
      };
    case 'SET_RESERVED_DATES':
      return {
        ...state,
        reservedDates: action.payload,
      };
    default:
      return state;
  }
};
