const firebaseConfig = {
  apiKey: "AIzaSyADsfEt-4677tC_LVPmxUgGjx5ud6uNMmU",
  authDomain: "espacomaljj-d9526.firebaseapp.com",
  projectId: "espacomaljj-d9526",
  storageBucket: "espacomaljj-d9526.appspot.com",
  messagingSenderId: "268470474249",
  appId: "1:268470474249:web:d2c321d49a373a0f5a4ad8",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCJ593Zl_WsgcUHxMsUA_1grd_Lw_iGFhY",
//   authDomain: "sandbox-6563c.firebaseapp.com",
//   projectId: "sandbox-6563c",
//   storageBucket: "sandbox-6563c.appspot.com",
//   messagingSenderId: "55098718999",
//   appId: "1:55098718999:web:32b2007f9f75a0adb81552"
// };

export default firebaseConfig;
