import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import { Layout } from 'antd';
import Sidebar from './components/Sidebar';
import ContentBreadcrumb from './components/ContentBreadcrumb';
import { Content } from 'antd/es/layout/layout';
import AppFooter from './components/Footer';
import { GlobalStyle } from './styles/global';

function App() {
  return (
    <>
      <GlobalStyle />
      <Router history={history}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sidebar />
          <Layout>
            <ContentBreadcrumb />
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 180,
                fontFamily: 'Poppins',
              }}
            >
              <Routes />
            </Content>
            <AppFooter />
          </Layout>
        </Layout>
      </Router>
    </>
  );
}

export default App;
