import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import {
  DollarCircleOutlined,
  EditOutlined,
  AlertOutlined,
  FieldBinaryOutlined,
  UserOutlined,
  FieldTimeOutlined,
  LoadingOutlined,
  PhoneOutlined,
  BankOutlined,
  SisternodeOutlined,
  PushpinOutlined,
} from '@ant-design/icons';
import { Icon, StyledFormItem, StyledModal } from './styled';
import { ModalProps } from './interfaces';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateReservation } from '../../../store/reservation/actions';
import { format } from 'date-fns';

const FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const ModalDetails: React.FC<ModalProps> = ({ isOpen, handleClose, reservation }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    if (reservation) {
      form.setFieldsValue({
        id: reservation.id,
        approvalDate: reservation.approvalDate
          ? format(new Date(reservation.approvalDate), 'dd/MM/yyyy HH:mm:ss')
          : new Date(),
        amount: reservation.amount,
        approved: reservation.approved ? 'Aprovada' : 'Pendente',
        startTime: reservation.startTime,
        endTime: reservation.endTime,
        document: reservation.document,
        entry: reservation.entry,
        name: reservation.name,
        payOff: reservation.payOff ? 'Pago' : 'Pendente',
        phone: reservation.phone,
        remaining: reservation.remaining,
        reportPayment: reservation.reportPayment,
        received: reservation.received,
        selectedDate: moment(reservation.selectedDate).format('DD/MM/YYYY'),
      });
    } else {
      form.resetFields();
    }
  }, [reservation, form]);

  const onClose = () => {
    form.resetFields();
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { reportPayment, remaining, payOff } = values;
      if (parseFloat(reportPayment) > parseFloat(remaining) && payOff === 'Pendente') {
        message.error('O valor de acerto não pode ser maior do que o valor pendente.');
      } else {
        dispatch(updateReservation(values));
        onClose();
      }
    } catch (error) {
      console.error('Erro ao enviar o agendamento:', error);
      message.error('Erro ao enviar os detalhes da reserva.');
    }
  };

  return (
    <StyledModal
      title={'Detalhes'}
      visible={isOpen}
      onOk={handleSubmit}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Confirmar
        </Button>,
      ]}
    >
      <Form form={form} {...FormItemLayout}>
        <StyledFormItem name="id" hidden>
          <Input type="hidden" />
        </StyledFormItem>
        <StyledFormItem
          name="approvalDate"
          label={
            <span>
              <Icon>
                <PushpinOutlined />
              </Icon>
              Data Cadastro
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="selectedDate"
          label={
            <span>
              <Icon>
                <FieldBinaryOutlined />
              </Icon>
              Data Evento
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="name"
          label={
            <span>
              <Icon>
                <UserOutlined />
              </Icon>
              Nome cliente
            </span>
          }
        >
          <Input placeholder="Nome cliente" disabled />
        </StyledFormItem>
        <StyledFormItem
          name="document"
          label={
            <span>
              <Icon>
                <UserOutlined />
              </Icon>
              Documento
            </span>
          }
        >
          <Input placeholder="Documento" disabled />
        </StyledFormItem>
        <StyledFormItem
          name="phone"
          label={
            <span>
              <Icon>
                <PhoneOutlined />
              </Icon>
              Contato
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="approved"
          label={
            <span>
              <Icon>
                <LoadingOutlined />
              </Icon>
              Status Reserva
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="startTime"
          label={
            <span>
              <Icon>
                <FieldTimeOutlined />
              </Icon>
              Início
            </span>
          }
        >
          <Input />
        </StyledFormItem>
        <StyledFormItem
          name="endTime"
          label={
            <span>
              <Icon>
                <FieldTimeOutlined />
              </Icon>
              Fim
            </span>
          }
        >
          <Input />
        </StyledFormItem>
        <StyledFormItem
          name="amount"
          label={
            <span>
              <Icon>
                <DollarCircleOutlined />
              </Icon>
              Valor Total
            </span>
          }
        >
          <Input placeholder="Valor Total" />
        </StyledFormItem>
        <StyledFormItem
          name="entry"
          label={
            <span>
              <Icon>
                <EditOutlined />
              </Icon>
              Entrada
            </span>
          }
        >
          <Input placeholder="Entrada" />
        </StyledFormItem>
        <StyledFormItem
          name="reportPayment"
          label={
            <span>
              <Icon>
                <SisternodeOutlined />
              </Icon>
              Acerto (R$)
            </span>
          }
        >
          <Input placeholder="Informe o pagamento" />
        </StyledFormItem>
        <StyledFormItem
          name="received"
          label={
            <span>
              <Icon>
                <BankOutlined />
              </Icon>
              Total Recebido
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="remaining"
          label={
            <span>
              <Icon>
                <DollarCircleOutlined />
              </Icon>
              Valor Pendente
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
        <StyledFormItem
          name="payOff"
          label={
            <span>
              <Icon>
                <AlertOutlined />
              </Icon>
              Situação
            </span>
          }
        >
          <Input disabled />
        </StyledFormItem>
      </Form>
    </StyledModal>
  );
};

export default ModalDetails;
