import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

export const StyledMaskedInput = styled(MaskedInput)`
  width: 95.5%;
  margin-bottom: 0.8rem;
  font-size: 14px;
  padding: 5px 10px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-family: 'Poppins', sans-serif;

  &&&:focus {
    outline: none;
    border: 1px solid #4a90e2;
    box-shadow: none;
  }

  &::placeholder {
    opacity: 0.3;
    color: inherit;
  }

  @media (max-width: 768px) {
    width: 94.5%;
  }

  @media (max-width: 450px) {
    width: 93.5%;
  }
`;
