import React from 'react';
import { Button, List, Typography, message } from 'antd';
import { DownloadOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import contractFile from '../../../assets/files/contrato_locacao.docx';
import { FooterButtons, StyledButton, StyledModal } from './styled';
import { AnimatedButton } from '../../../screens/AboutScreen/styled';

const { Title } = Typography;
const pix_number = '46941905000167';

interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedDate: Date | null;
  handlePaymentOptionSelect: (option: string) => void;
}

const ModalPayment: React.FC<ModalProps> = ({ isOpen, handleClose }) => {
  const handleConfirm = async () => {
    handleClose();
  };

  const handleDownloadContract = () => {
    const link = document.createElement('a');
    link.href = contractFile;
    link.download = 'contrato_locacao.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.success('O contrato está sendo baixado.');
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(pix_number)
      .then(() => {
        message.success('Número do PIX copiado para a área de transferência!');
      })
      .catch((err) => {
        console.error('Falha ao copiar: ', err);
        message.error('Erro ao copiar o número PIX.');
      });
  };

  return (
    <StyledModal
      title={<Title level={5}>Confirmação de Pagamento</Title>}
      visible={isOpen}
      onOk={handleConfirm}
      onCancel={handleClose}
      footer={[
        <FooterButtons>
          <StyledButton key="download" onClick={handleDownloadContract} icon={<DownloadOutlined />}>
            Baixar Contrato
          </StyledButton>
          <StyledButton key="submit" type="primary" onClick={handleConfirm}>
            Confirmar
          </StyledButton>
        </FooterButtons>,
      ]}
      centered
      destroyOnClose
    >
      <Typography.Paragraph>
        Clique abaixo para copiar o <strong>PIX</strong> e realizar o pagamento da metade (50%) do valor total da locação.
      </Typography.Paragraph>
      <Typography.Paragraph>
        Chave: <strong>46.941.905/0001-67</strong>
      </Typography.Paragraph>
      <AnimatedButton style={{ marginBottom: 15 }} onClick={handleCopyToClipboard} icon={<DownloadOutlined />}>
        CLIQUE AQUI PARA COPIAR A CHAVE PIX
      </AnimatedButton>
      <Typography.Paragraph>Ao realizar o pagamento, favor enviar o comprovante para o(s) número(s):</Typography.Paragraph>
      <Typography.Paragraph>
        <strong>(31) 99813-5312</strong> ou <strong>(31) 99494-8900</strong>
      </Typography.Paragraph>
      <Typography.Paragraph>
        <InfoCircleOutlined /> IMPORTANTE: A reserva só será concluída mediante a confirmação de pagamento.
      </Typography.Paragraph>
      <List
        itemLayout="horizontal"
        dataSource={[
          'Proibido locação do espaço para eventos remunerados.',
          'Respeite o espaço em geral, e se atente aos horários de contratação para não sofrer encargos.',
          'Não é permitido ultrapassar o máximo de pessoas acordadas na negociação.',
        ]}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta avatar={<WarningOutlined style={{ color: 'red' }} />} title={item} />
          </List.Item>
        )}
      />
    </StyledModal>
  );
};

export default ModalPayment;
