import { parse, format } from 'date-fns';
import { Button } from 'antd';
import { EyeOutlined, DeleteOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons';

type HandleApproveReservation = (id: string) => void;
type HandleCancelRequest = (id: string) => void;

type HandleDetailsFunction = (id: string) => void;
type HandleCancelFunction = (id: string) => void;

export const columnsPreReservation = (
  handleApproveReservation: HandleApproveReservation,
  handleCancelRequest: HandleCancelRequest,
) => [
  {
    title: 'Data',
    dataIndex: 'selectedDate',
    key: 'selectedDate',
    sorter: (a: any, b: any) => {
      const dateA = parse(a.selectedDate, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.selectedDate, 'yyyy-MM-dd', new Date());
      return dateA.getTime() - dateB.getTime();
    },
    render: (date: any) => {
      if (!date) return '-';
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      return format(parsedDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Documento',
    dataIndex: 'document',
    key: 'document',
  },
  {
    title: 'Telefone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Ações',
    key: 'actions',
    render: (text: string, record: any) => (
      <>
        <Button
          icon={<LikeOutlined />}
          onClick={() => handleApproveReservation(record.id)}
          style={{ marginRight: '10px' }}
        />
        <Button
          icon={<DislikeOutlined />}
          onClick={() => handleCancelRequest(record.id)}
          style={{ marginRight: '10px' }}
        />
      </>
    ),
  },
];

export const columnsFutureReservation = (handleDetails: HandleDetailsFunction, handleCancel: HandleCancelFunction) => [
  {
    title: 'Ver',
    key: 'see',
    render: (text: string, record: any) => (
      <Button icon={<EyeOutlined />} onClick={() => handleDetails(record.id)} style={{ marginRight: '5px' }} />
    ),
  },
  {
    title: 'Data',
    dataIndex: 'selectedDate',
    key: 'selectedDate',
    sorter: (a: any, b: any) => {
      const dateA = parse(a.selectedDate, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.selectedDate, 'yyyy-MM-dd', new Date());
      return dateA.getTime() - dateB.getTime();
    },
    render: (date: any) => {
      if (!date) return '-';
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      return format(parsedDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Início',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (record: any) => {
      return record ? record : 'N/A';
    },
  },
  {
    title: 'Fim',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (record: any) => {
      return record ? record : 'N/A';
    },
  },
  {
    title: 'Ações',
    key: 'actions',
    render: (text: string, record: any) => <Button icon={<DeleteOutlined />} onClick={() => handleCancel(record.id)} />,
  },
];

export const columnsReservation = (handleDetails: HandleDetailsFunction, handleCancel: HandleCancelFunction) => [
  {
    title: 'Ver',
    key: 'see',
    render: (text: string, record: any) => (
      <Button icon={<EyeOutlined />} onClick={() => handleDetails(record.id)} style={{ marginRight: '5px' }} />
    ),
  },
  {
    title: 'Data',
    dataIndex: 'selectedDate',
    key: 'selectedDate',
    sorter: (a: any, b: any) => {
      const dateA = parse(a.selectedDate, 'yyyy-MM-dd', new Date());
      const dateB = parse(b.selectedDate, 'yyyy-MM-dd', new Date());
      return dateA.getTime() - dateB.getTime();
    },
    render: (date: any) => {
      if (!date) return '-';
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
      return format(parsedDate, 'dd/MM/yyyy');
    },
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: any, b: any) => a.name.localeCompare(b.name),
  },
  {
    title: 'Início',
    dataIndex: 'startTime',
    key: 'startTime',
    render: (record: any) => {
      return record ? record : 'N/A';
    },
  },
  {
    title: 'Fim',
    dataIndex: 'endTime',
    key: 'endTime',
    render: (record: any) => {
      return record ? record : 'N/A';
    },
  },
  {
    title: 'Valor Total',
    dataIndex: 'amount',
    key: 'amount',
    render: (record: any) => {
      return record
        ? parseFloat(record).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : 'N/A';
    },
  },
  {
    title: 'Entrada',
    dataIndex: 'entry',
    key: 'entry',
    render: (record: any) => {
      return record
        ? parseFloat(record).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : 'N/A';
    },
  },
  {
    title: 'Recebido',
    dataIndex: 'received',
    key: 'received',
    render: (record: any) => {
      return record
        ? parseFloat(record).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : 'N/A';
    },
  },
  {
    title: 'Restante',
    dataIndex: 'remaining',
    key: 'remaining',
    render: (record: any) => {
      return record
        ? parseFloat(record).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        : 'N/A';
    },
  },
  {
    title: 'Ações',
    key: 'actions',
    render: (text: string, record: any) => <Button icon={<DeleteOutlined />} onClick={() => handleCancel(record.id)} />,
  },
];
