import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Form, Input, Button, notification, Row, Col, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LargeScreenTitle } from './styled';

function Login() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (values: any) => {
    try {
      setLoading(true);
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, values.email, values.password);
      history.push('/admin');
    } catch (error) {
      notification['error']({
        message: 'Erro de Login',
        description: 'Credenciais inválidas. Tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: '50vh', marginBottom: '10%' }}>
      <Col xs={20} sm={16} md={12} lg={8}>
        <Card bordered={false} style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)' }}>
          <LargeScreenTitle level={2}>Autenticação</LargeScreenTitle>
          <Form onFinish={handleLogin} layout="vertical" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <Form.Item
              label="E-mail"
              name="email"
              rules={[{ required: true, message: 'Por favor, insira seu e-mail!' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Senha" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Login;
