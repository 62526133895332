import { Carousel, Typography } from 'antd';
import styled from 'styled-components';

export const StyledCarousel = styled(Carousel)`
  .slick-slide img {
    width: 100%;
    height: auto;
    max-height: 600px;
    min-height: 600px;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export const StyledTitle = styled(Typography.Title)`
  text-align: center;
  color: #78527d;
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  text-align: center;
  color: #78527d;
  margin-bottom: 40px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
`;
