import React, { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...props }) => {
    const auth = getAuth();
    const [user] = useAuthState(auth);
    const isLogged = user !== null;

    useEffect(() => {
        return () => {
            auth.signOut();
        };
    }, [auth]);

    return (
        <Route
            {...props}
            render={(routeProps) =>
                isLogged ? <Component {...routeProps} /> : <Redirect to={{ pathname: '/login' }} />
            }
        />
    );
};

export default PrivateRoute;