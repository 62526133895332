import { Row } from 'antd';
import Calendar from 'react-calendar';
import styled from 'styled-components';

export const StyledRow = styled(Row)`
  justify-content: center;
  margin-bottom: 20px;
`;

export const StyledCalendar = styled(Calendar)`
  font-family: 'Poppins', sans-serif !important;

  .react-calendar__month-view__days__day {
    border-radius: 16px;
    font-weight: bold;
    color: #8C8C8C;
    border: none;
    cursor: pointer;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s, transform 0.2s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    a {
      color: inherit;
      text-decoration: none;
    }

    @media (max-width: 768px) {
      height: 40px;
    }
  }

  .react-calendar__month-view__days { 
  }

  .react-calendar__tile--now {
    background-color: #a7d2b9;
  }

  .react-calendar__month-view__weekdays {
    font-weight: bold;
    color: #555;
  }

  .calendar-day {
    background-color: #229954;
    transition: transform 0.2s, box-shadow 0.2s;
    color: #ffffff;

    &:hover {
      transform: translateY(0.1px);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
      background-color: #2fb969;
    }
  }

  .calendar-day--unavailable {
    background-color: #9e2e17;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.8;
  }

  .react-calendar__navigation button {
    border: none;
    font-family: 'Poppins', sans-serif !important;
    background-color: transparent;
    font-size: 16px;
    color: #333;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 10px;
    margin-bottom: 5%;
    padding: 5px 10px;

    &:hover {
      background-color: #f0f0f0;
    }

    @media (max-width: 768px) {
      margin: 0 2px;
      padding: 3px 5px;
      margin-bottom: 10%;
    }
  }
`;
