import { put, call, PutEffect, CallEffect, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  fetchReservedDatesApi,
  onApproveReservationApi,
  onReproveReservationApi,
  onCancelReservationApi,
  fetchReservationApi,
  registerReservationApi,
  updateReservationApi,
} from '../../services/firebaseFunctions';
import { ReservedDateRequest, Reservation } from '../../store/reservation/types';
import { message } from 'antd';

function* fetchReservationSaga(): Generator<PutEffect | CallEffect, void, any> {
  try {
    const response: Reservation[] = yield call(fetchReservationApi);
    yield put({ type: 'SET_RESERVATION', payload: response });
  } catch (error) {
    throw error;
  }
}

function* fetchReservedDatesSaga(): Generator<PutEffect | CallEffect, void, any> {
  try {
    const reservedDates: ReservedDateRequest[] = yield call(fetchReservedDatesApi);
    yield put({ type: 'SET_RESERVED_DATES', payload: reservedDates });
  } catch (error) {
    throw error;
  }
}

function* approveReservationSaga(action: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield call(onApproveReservationApi, action.payload);
    yield put({ type: 'FETCH_RESERVATION' });
    yield put({ type: 'FETCH_RESERVED_DATES' });
  } catch (error) {
    throw error;
  }
}

function* reproveReservationSaga(action: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield call(onReproveReservationApi, action.payload);
    yield put({ type: 'FETCH_RESERVATION' });
    yield put({ type: 'FETCH_RESERVED_DATES' });
  } catch (error) {
    throw error;
  }
}

function* cancelReservationSaga(action: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield call(onCancelReservationApi, action.payload);
    yield put({ type: 'FETCH_RESERVATION' });
    yield put({ type: 'FETCH_RESERVED_DATES' });
  } catch (error) {
    throw error;
  }
}

function* registerReservationSaga(action: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield call(registerReservationApi, action.payload);
    yield put({ type: 'FETCH_RESERVATION' });
    yield put({ type: 'FETCH_RESERVED_DATES' });
  } catch (error) {
    throw error;
  }
}

function* updateReservationSaga(action: PayloadAction<string>): Generator<PutEffect | CallEffect, void, any> {
  try {
    yield call(updateReservationApi, action.payload);
    yield put({ type: 'FETCH_RESERVATION' });
    yield put({ type: 'FETCH_RESERVED_DATES' });
    message.success('Reserva atualizada com sucesso!');
  } catch (error) {
    throw error;
  }
}

export function* watchReservationSaga() {
  yield takeLatest('FETCH_RESERVATION', fetchReservationSaga);
  yield takeLatest('FETCH_RESERVED_DATES', fetchReservedDatesSaga);
  yield takeLatest('APPROVE_RESERVATION', approveReservationSaga);
  yield takeLatest('REPROVE_RESERVATION', reproveReservationSaga);
  yield takeLatest('CANCEL_RESERVATION', cancelReservationSaga);
  yield takeLatest('REGISTER_RESERVATION', registerReservationSaga);
  yield takeLatest('UPDATE_RESERVATION', updateReservationSaga);
}
