import {
  getFirestore,
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  addDoc,
} from 'firebase/firestore';
import { ReservedDateRequest, Reservation } from '../store/reservation/types';

const db_collection = 'reservations';

export async function fetchReservationApi() {
  try {
    const db = getFirestore();
    const reservationsRef = collection(db, db_collection);
    const q = query(reservationsRef, orderBy('selectedDate', 'desc'));
    const querySnapshot = await getDocs(q);
    const response: Reservation[] = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        approvalDate: doc.data().approvalDate,
        selectedDate: doc.data().selectedDate,
        approved: doc.data().approved,
        startTime: doc.data().startTime,
        endTime: doc.data().endTime,
        name: doc.data().name,
        phone: doc.data().phone,
        document: doc.data().document,
        amount: doc.data().amount,
        entry: doc.data().entry,
        payOff: doc.data().payOff,
        remaining: doc.data().remaining,
        reportPayment: doc.data().reportPayment,
        received: doc.data().received,
      };
    });
    return response;
  } catch (error) {
    console.error('Erro ao buscar os registros:', error);
  }
}

export async function fetchReservedDatesApi() {
  try {
    const db = getFirestore();
    const reservationsRef = collection(db, db_collection);
    const q = query(reservationsRef, orderBy('selectedDate', 'desc'));
    const querySnapshot = await getDocs(q);
    const dates = querySnapshot.docs.map((doc) => {
      const reservationDate = doc.data().selectedDate;
      return {
        date: reservationDate,
        approved: doc.data().approved,
      };
    });
    return dates.filter((date) => date !== null) as ReservedDateRequest[];
  } catch (error) {
    console.error('Erro ao buscar as datas reservadas:', error);
  }
}

export async function onApproveReservationApi(requestId: any) {
  try {
    const db = getFirestore();
    const reservationRef = doc(db, 'reservations', requestId);
    await updateDoc(reservationRef, {
      approved: true,
    });
  } catch (error) {
    console.error('Erro ao aprovar a solicitação de reserva:', error);
    throw error;
  }
}

export async function onReproveReservationApi(requestId: any) {
  try {
    const db = getFirestore();
    const reservationRef = doc(db, 'reservations', requestId);
    await deleteDoc(reservationRef);
  } catch (error) {
    console.error('Erro ao reprovar a solicitação de reserva:', error);
    throw error;
  }
}

export async function onCancelReservationApi(selectedReservationId: string) {
  try {
    const db = getFirestore();
    const reservationRef = doc(db, 'reservations', selectedReservationId);
    await deleteDoc(reservationRef);
  } catch (error) {
    console.error('Erro ao cancelar a reserva:', error);
  }
}

export async function registerReservationApi(reservation: any) {
  try {
    const db = getFirestore();
    const reservationsRef = collection(db, 'reservations');
    await addDoc(reservationsRef, reservation);
  } catch (error) {
    console.error('Erro ao cancelar a reserva:', error);
  }
}

export async function updateReservationApi(reservation: any) {
  try {
    const { id, startTime, endTime, amount, entry, reportPayment } = reservation;
    const db = getFirestore();
    const reservationRef = doc(db, db_collection, id);
    const amountValue = parseFloat(amount) || 0.0;
    const entryValue = parseFloat(entry) || 0.0;
    const reportPaymentValue = parseFloat(reportPayment) || 0.0;
    const receivedValue = entryValue + reportPaymentValue;
    const remainingValue = amountValue - entryValue - reportPaymentValue;
    const reservationDetailsData = {
      startTime: startTime ? startTime : 'N/A',
      endTime: endTime ? endTime : 'N/A',
      amount: amountValue,
      entry: entryValue,
      reportPayment: reportPaymentValue,
      received: receivedValue,
      remaining: remainingValue,
      payOff: remainingValue !== 0 ? false : true,
    };
    await updateDoc(reservationRef, reservationDetailsData);
  } catch (error) {
    console.error('Erro ao atualizar reserva:', error);
  }
}
