import React from 'react';
import { Layout, Row, Col, Divider, Space } from 'antd';
import { InstagramOutlined, WhatsAppOutlined } from '@ant-design/icons';

const { Footer } = Layout;

const AppFooter: React.FC = () => {
  return (
    <Footer style={{ background: '#E7E7E7', padding: '15px 20px' }}>
      <Row gutter={[16, 32]}>
        <Col xs={24} md={8}>
          <h3 style={{ fontFamily: 'Poppins' }}>Espaço Maljj</h3>
          <Space size="middle">
            <a href="https://www.instagram.com/espaco_maljj" target="_blank" rel="noopener noreferrer">
              <InstagramOutlined style={{ fontSize: '20px' }} />
            </a>
            <a href="https://api.whatsapp.com/send?phone=5531994948900" target="_blank" rel="noopener noreferrer">
              <WhatsAppOutlined style={{ fontSize: '20px' }} />
            </a>
          </Space>
        </Col>
        <Col xs={24} md={8} style={{ fontFamily: 'Poppins' }}></Col>
        <Col xs={24} md={8}>
          <p style={{ fontFamily: 'Poppins' }}>
            <strong>Email:</strong> espacomaljj1@gmail.com
          </p>
          <p style={{ fontFamily: 'Poppins' }}>
            <strong>Telefone:</strong> +55 (31) 99494-8900
          </p>
        </Col>
      </Row>
      <Divider style={{ borderColor: '#444' }} />
      <Row justify="center">
        <Col style={{ fontFamily: 'Poppins' }}>
          ©{new Date().getFullYear()} SMARF TECHNOLOGY - Todos os direitos reservados.
        </Col>
      </Row>
    </Footer>
  );
};

export default AppFooter;
