import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { HomeOutlined, InfoCircleOutlined, KeyOutlined, FileSearchOutlined, MenuOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuItem(pathname);
  }, [location]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);
      if (window.innerWidth > 1000 && visible) {
        setVisible(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  const iconStyle = {
    fontSize: '16px',
    marginRight: '10px',
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const content = (
    <Menu
      mode="inline"
      selectedKeys={[selectedMenuItem]}
      style={{ height: '100%', borderRight: 0, fontFamily: 'Poppins' }}
      onClick={isMobile ? () => setVisible(false) : undefined}
    >
      <Menu.Item key="/" icon={<HomeOutlined style={iconStyle} />}>
        <NavLink to="/">Agenda</NavLink>
      </Menu.Item>
      <Menu.Item key="/about" icon={<InfoCircleOutlined style={iconStyle} />}>
        <NavLink to="/about">Saiba Mais</NavLink>
      </Menu.Item>
      <Menu.Item key="/media" icon={<FileSearchOutlined style={iconStyle} />}>
        <NavLink to="/media">Mídias</NavLink>
      </Menu.Item>
      <Menu.Item key="/admin" icon={<KeyOutlined style={iconStyle} />}>
        <NavLink to="/admin">Restrito</NavLink>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {isMobile && (
        <>
          <Drawer
            title="Menu"
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            bodyStyle={{ padding: 0 }}
            width={isMobile ? 200 : undefined}
          >
            {content}
          </Drawer>
          <MenuOutlined onClick={showDrawer} style={{ padding: '10px', fontSize: '15px', lineHeight: '32px', cursor: 'pointer' }} />
        </>
      )}

      {!isMobile && (
        <Sider width={200} collapsed={collapsed} onCollapse={toggleCollapsed}>
          {content}
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
