import { Typography, Collapse, List, Space, Divider, Card, Row, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import pixImage from '../../assets/images/payments_images/pix.png';
import visaImage from '../../assets/images/payments_images/visa.png';
import masterImage from '../../assets/images/payments_images/mastercard.png';
import contractFile from '../../assets/files/contrato_locacao.docx';
import { DownloadOutlined } from '@ant-design/icons';
import { LargeScreenTitle, AnimatedButton, CustomCollapse } from './styled';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const { Panel } = Collapse;
const { Title, Text } = Typography;
const { Item } = List;

const CustomPanel = styled(Panel)`
  && {
    background-color: #f7f7f7;
    border-radius: 4px;
    margin-bottom: 5px;
    border: 0;
    overflow: hidden;
    margin-bottom: 24;
  }
`;

function AboutScreen() {
  const handleDownloadContract = () => {
    const link = document.createElement('a');
    link.href = contractFile;
    link.download = 'contrato_locacao.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.success('O contrato está sendo baixado.');
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
      <Row justify="center">
        <Card bordered={false}>
          <LargeScreenTitle level={2}>Espaço Maljj</LargeScreenTitle>
          <Text
            style={{
              textAlign: 'justify',
              display: 'block',
              marginBottom: 24,
              fontFamily: 'Poppins',
            }}
          >
            O Espaço foi projetado para proporcionar excelência ao seu evento e está localizado no centro comercial do bairro Botafogo na
            cidade de RIBEIRÃO DAS NEVES/ MG. Oferecemos uma estrutura moderna, materiais de qualidade e uma área verde com lindo pergolado,
            capaz de proporcionar descanso e bem-estar para você e seus convidados! Faça seu evento com a gente!
          </Text>
          <CustomCollapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
          >
            <CustomPanel header={<Title level={5}>Espaço e Serviços</Title>} key="1">
              <List size="small">
                <div style={{ fontFamily: 'Poppins' }}>
                <Item><strong>Pagamento</strong></Item>
                <Item>* Pix, Dinheiro e Cartão</Item>
                <Item><strong>Horário:</strong></Item>
                <Item>* 12H de uso</Item>
                <Item>* 11:00 as 23:00H</Item>
                <Item><strong>Capacidade:</strong></Item>
                <Item>* 100 pessoas</Item>
                <Item><strong>Estrutura:</strong></Item>
                <Item>* Piscina aquecida</Item>
                <Item>* Playground 3 andares (Brinquedão Kidplay)</Item>
                <Item>* 25 jogos de mesas</Item>
                <Item>* Som, TV e Youtube Premium</Item>
                <Item>* Bebedouro (Água gelada)</Item>
                <Item>* Cervejeira 537L</Item>
                <Item>* Fogão industrial 4 bocas (Não inclui gás)</Item>
                <Item>* Churrasqueira 2 grelhas</Item>
                <Item>* Wi-Fi</Item>
                <Item>* Pergolado de madeira</Item>
                <Item>* Espaco 360m²</Item>
                <Item>* Banheiro Feminino, Masculino e Acessibilidade (Cadeirante)</Item>
                <Item>* Alvara de funcionamento</Item>
                <Item>* Alvara do corpo de bombeiros AVCB (Com placas de sinalização e extintores)</Item>
                <Item><strong>Observação:</strong></Item>
                <Item>* Não cobramos taxa de limpeza</Item>
                <Item>* Taxa para utilização do nosso gás R$ 40,00</Item>
                </div>
              </List>
            </CustomPanel>
          </CustomCollapse>
          <Divider />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 24,
            }}
          >
            <AnimatedButton onClick={handleDownloadContract} icon={<DownloadOutlined />}>
              Baixar Contrato
            </AnimatedButton>
          </div>
          <Text
            style={{
              textAlign: 'center',
              display: 'block',
              marginBottom: 24,
              fontFamily: 'Poppins',
            }}
          >
            Aceitamos cartão de crédito, débito e PIX.
          </Text>
          <Space style={{ display: 'flex', justifyContent: 'center' }} size={[8, 16]} wrap>
            <img src={pixImage} alt="PIX" style={{ width: '50px', height: '50px' }} />
            <img src={visaImage} alt="VISA" style={{ width: '50px', height: '50px' }} />
            <img src={masterImage} alt="MasterCard" style={{ width: '50px', height: '50px' }} />
          </Space>
        </Card>
      </Row>
    </motion.div>
  );
}

export default AboutScreen;
