import { Typography } from "antd";
import styled from "styled-components";

const { Title } = Typography;

export const LargeScreenTitle = styled(Title)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-family: 'Poppins';

  @media (max-width: 1200px) {
    font-size: 1rem !important;
    margin-bottom: 50px !important;
  }
`;
