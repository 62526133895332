import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import HomeScreen from "../screens/HomeScreen";
import AboutScreen from "../screens/AboutScreen";
import LoginScreen from "../screens/LoginScreen";
import AdminScreen from "../screens/AdminScreen";
import MediaScreen from "../screens/MediaScreen";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={HomeScreen} />
      <Route path="/about" component={AboutScreen} />
      <Route path="/media" component={MediaScreen} />
      <Route path="/login" component={LoginScreen} />
      <PrivateRoute exact path="/admin" component={AdminScreen} />
    </Switch>
  );
}
