import { Card, Col, Row } from 'antd';
import { StyledCarousel } from './styled';
import { images } from './interfaces';

function FuturisticCarousel() {
  return (
    <Card title={`Galeria do Espaço Maljj`}>
      <Row justify="center">
        <Col xs={24} sm={24} md={18} lg={16} xl={14}>
          <StyledCarousel autoplay dotPosition="top" centerMode centerPadding="5px 0">
            {images.map((img, index) => (
              <div key={index}>
                <img src={img} alt={`Imagem ${index + 1}`} />
              </div>
            ))}
          </StyledCarousel>
        </Col>
      </Row>
    </Card>
  );
}
export default FuturisticCarousel;
