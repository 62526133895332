import { Card, Layout, Typography } from "antd";
import { motion } from "framer-motion";
import { ExclamationCircleOutlined, ScheduleOutlined } from "@ant-design/icons";
import CustomCalendar from "../../components/CustomCalendar";

function HomeScreen() {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <Layout>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.3 }}
      >
        <div style={{ paddingBottom: 30 }}>
          <Card
            title={
              <span>
                <ScheduleOutlined /> Escolha a data e reserve!
              </span>
            }
          >
            <CustomCalendar />
          </Card>
        </div>
        <div style={{ paddingBottom: 30 }}>
          <Card
            title={
              <span>
                <ExclamationCircleOutlined /> Informações
              </span>
            }
          >
            <Typography.Text>
              <p>
                Dias em <strong style={{ color: "#9E2E17" }}>vermelho</strong>{" "}
                já estão reservados, selecione outra data.
              </p>
              <p>
                Dias <strong style={{ color: "#229954" }}>verde</strong>{" "}
                estão disponíveis.
              </p>
            </Typography.Text>
          </Card>
        </div>
      </motion.div>
    </Layout>
  );
}

export default HomeScreen;
