export const images = [
  require('../../assets/slide/01.jpeg'),
  require('../../assets/slide/02.jpeg'),
  require('../../assets/slide/03.jpeg'),
  require('../../assets/slide/04.jpeg'),
  require('../../assets/slide/05.jpeg'),
  require('../../assets/slide/06.jpeg'),
  require('../../assets/slide/07.jpeg'),
  require('../../assets/slide/08.jpeg'),
  require('../../assets/slide/09.jpeg'),
  require('../../assets/slide/10.jpeg'),
  require('../../assets/slide/11.jpeg'),
  require('../../assets/slide/12.jpeg'),
  require('../../assets/slide/13.jpeg'),
  require('../../assets/slide/14.jpeg'),
  require('../../assets/slide/15.jpeg'),
  require('../../assets/slide/16.jpeg'),
  require('../../assets/slide/17.jpeg'),
  require('../../assets/slide/18.jpeg'),
  require('../../assets/slide/19.jpeg'),
  require('../../assets/slide/20.jpeg'),
  require('../../assets/slide/21.jpeg')
];
