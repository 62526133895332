import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import { initializeApp } from 'firebase/app';
import firebaseConfig from './configs/firebaseConfig';
import Modal from 'react-modal';

initializeApp(firebaseConfig);
Modal.setAppElement('#root');
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);