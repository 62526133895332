import { DatePicker } from 'antd';
import styled from 'styled-components';

export const StyledRangePickerContainer = styled.div`
  @media (max-width: 576px) {
    .ant-picker-panels {
      flex-direction: column !important;
      font-size: 10px;
    }
  }
`;

export const RangeDatePicker = (props: any) => (
  <DatePicker.RangePicker
    panelRender={(panelNode) => <StyledRangePickerContainer>{panelNode}</StyledRangePickerContainer>}
    {...props}
  />
);
