import React from 'react';
import { Button, Modal, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { cancelReservation } from '../../../store/reservation/actions';

const StyledModal = styled(Modal)`
  @media (max-width: 768px) {
    .ant-modal {
      width: 100% !important;
      margin: 0;
    }
  }
`;

interface ModalProps {
  reservation: any;
  isOpen: boolean;
  handleClose: () => void;
}

const ModalCancelReservation: React.FC<ModalProps> = ({ reservation, isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const handleRemoveReservation = async () => {
    dispatch(cancelReservation(reservation.id));
    message.success('A reserva foi cancelada com sucesso!');
    handleClose();
  };

  return (
    <StyledModal
      visible={isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          Fechar
        </Button>,
        <Button key="submit" type="primary" onClick={handleRemoveReservation}>
          Confirmar
        </Button>,
      ]}
    >
      <div style={{ marginBottom: 25 }}>
        <h2 style={{ fontFamily: 'Poppins' }}>
          <DeleteOutlined /> Exclusão de reserva
        </h2>
        <p style={{ fontFamily: 'Poppins' }}>
          Deseja realmente excluir a reserva de <strong>{reservation.name}</strong>?
        </p>
      </div>
    </StyledModal>
  );
};

export default ModalCancelReservation;
