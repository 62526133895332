export const calculateTotalAmount = (data: any) => {
  const totalAmount = data.reduce((acc: any, item: any) => {
    const amount = parseFloat(item.amount || '0');
    return acc + amount;
  }, 0);
  const formattedTotal = totalAmount.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedTotal;
};

export const calculateTotalReceived = (data: any) => {
  const totalAmount = data.reduce((acc: any, item: any) => {
    const amount = parseFloat(item.received || '0');
    return acc + amount;
  }, 0);
  const formattedTotal = totalAmount.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedTotal;
};

export const calculateTotalRemaining = (data: any) => {
  const totalRemaining = data.reduce((acc: any, item: any) => {
    const remaining = parseFloat(item.remaining || '0');
    return acc + remaining;
  }, 0);
  const formattedTotal = totalRemaining.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedTotal;
};
